<template>
     <spam>lista</spam>
</template>
<script>
export default {
    setup() {
        return {
            
        }
    },
}
</script>