<template>
  <v-card>
    <v-card-title> Procesar propinas empleado </v-card-title>
    <v-card-text>
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <cabecera-empresa></cabecera-empresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <div>
            <h2 v-if="!soloVer" class="pt-12 pb-2">Cuenta # <small>Nuevo</small></h2>
            <span v-if="soloVer" class="m-0 p-0">
              Fecha creacion: {{ FuncionesGenerales.formatoFecha(datos.fecha_creacion, 3) }}
            </span>
            <br />
            <span v-if="soloVer" class="m-0 p-0">
              Empleado: {{ datos.empleado.nombre }} {{ datos.empleado.apellido }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-divider></v-divider>

      <v-row class="mt-4 mb-2">
        <v-col>
          <div v-if="datos.propinas.length == 0">
            <buscar-empleado @Getdatos="GetEmpleado"></buscar-empleado>
          </div>
          <div v-if="datos.propinas.length > 0">
            <h4>
              Empleado
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon color="warning" @click="Reiniciar()">
                    <v-icon>{{ icons.mdiCached }}</v-icon>
                  </v-btn>
                </template>
                <span>Reiniciar</span>
              </v-tooltip>
            </h4>
            <span>Nombre: {{ empleado.nombre }} {{ empleado.apellido }}</span>
            <br />
            <span>Ci: {{ empleado.ci }} </span>
            <br />
            <span>Telefono: {{ empleado.telefono }}</span>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mb-4">
        <v-col>
          <v-card-subtitle class="pt-4 pb-3 secondary white--text">
            <v-row>
              <v-col>
                <span>
                  <v-icon color="white" class="mt-0 pt-0">{{ icons.mdiCart }}</v-icon> Propinas
                </span>
              </v-col>
              <v-col class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="soloVer"
                      color="white"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="selectPropinas(0)"
                    >
                      <v-icon>{{ icons.mdiCheckboxBlankOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Deseleccionar todo</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="soloVer"
                      color="white"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="selectPropinas(-1)"
                    >
                      <v-icon>{{ icons.mdiCheckboxMarked }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar todo</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="soloVer"
                      color="white"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="selectPropinas(100)"
                    >
                      <v-icon>{{ icons.mdiCheckboxMarkedOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar personalizado</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <div>
            <v-simple-table dense fixed-header height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th>Cajero</th>
                    <th>Moneda</th>
                    <th class="text-right">Monto</th>
                    <th class="text-right">Monto $</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in datos.propinas" :key="item.id">
                    <td>
                      <div>
                        <v-checkbox
                          v-model="item.checked"
                          @change="Totalizar()"
                          class="mt-0 pt-0"
                          hide-details="true"
                          :label="`#${item.id}`"
                          v-if="!soloVer"
                        >
                        </v-checkbox>
                      </div>
                      <span v-if="soloVer">#2</span>
                      {{ FuncionesGenerales.formatoFecha(item.fecha, 1) }}
                    </td>
                    <td>
                      {{ item.cajero }}
                    </td>
                    <td>
                      <span>{{ item.moneda_nombre }}</span> <br />
                      <small>{{ item.tipo_pago_nombre }}</small>
                    </td>
                    <td class="text-right">
                      <span>{{ item.simbolo }} {{ item.monto_moneda.toFixed(2) }}</span
                      ><br />
                      <small v-if="item.tasa != 1">Tasa: {{ item.tasa.toFixed(2) }}</small>
                    </td>
                    <td class="text-right">
                      <span>$ {{ item.monto_real.toFixed(2) }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row class="pt-4">
              <v-col cols="12" sm="12" md="6" lg="8">
                <v-simple-table dense>
                  <template v-slot:default>
                    <th colspan="3" class="text-center grey2"><small class=" ">Tipos pagos</small></th>
                    <tr v-for="item in datos.totales.tipos" :key="item.tipo_pago">
                      <td>{{ item.simbolo }} {{ item.tipo_pago_nombre }}</td>
                      <td :colspan="item.simbolo == '$' ? '2' : '0'" class="text-right">
                        {{ item.simbolo }} {{ item.monto_moneda.toFixed(2) }}
                      </td>
                      <td v-if="item.simbolo != '$'" class="text-right">${{ item.monto_real.toFixed(2) }}</td>
                    </tr>
                  </template>
                </v-simple-table>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" sm="12" md="6" lg="4">
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <th colspan="2" class="text-center grey2"><small class=" ">Totales</small></th>
                    <tr>
                      <td>Total</td>
                      <td class="text-right">${{ datos.totales.total.toFixed(2) }}</td>
                    </tr>

                    <tr>
                      <td>Seleccionados</td>
                      <td class="text-right">${{ datos.totales.seleccionados.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td>Diferencia</td>
                      <td class="text-right">${{ datos.totales.diferiencia.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                    </tr>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import BuscarEmpleado from '../../../archivo/empleado/components/BuscarEmpleado.vue'
import {
  mdiCart,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckboxIntermediate,
  mdiCheckboxMarkedOutline,
} from '@mdi/js'
import ComandaService from '@/api/servicios/ComandaService'
import store from '@/store'
export default {
  components: {
    CabeceraEmpresa,
    BuscarEmpleado,
  },
  setup() {
    const soloVer = ref(false)
    const datos = ref({
      propinas: [],
      totales: {
        seleccionados: 0,
        total: 0,
        diferiencia: 0,
        tipos: [],
      },
    })
    const filtro = ref({
      id_empleado: 0,
      estado_pago: 7,
      fecha: {
        desde: '20200101',
        hasta: '20200101',
      },
    })
    const empleado = ref({})
    const Totalizar = () => {
      datos.value.totales = {
        seleccionados: 0,
        total: 0,
        diferiencia: 0,
        tipos: [],
      }
      datos.value.propinas.forEach((element, i) => {
        datos.value.totales.total += element.monto_real
        if (element.checked == true) {
          datos.value.totales.seleccionados += element.monto_real
          if (datos.value.totales.tipos.find(item => item.tipo_pago == element.tipo_pago) != undefined) {
            datos.value.totales.tipos.find(item => item.tipo_pago == element.tipo_pago).monto_moneda +=
              element.monto_moneda
            datos.value.totales.tipos.find(item => item.tipo_pago == element.tipo_pago).monto_real += element.monto_real
          } else {
            datos.value.totales.tipos.push({
              tipo_pago: element.tipo_pago,
              monto_real: element.monto_real,
              monto_moneda: element.monto_moneda,
              tipo_pago_nombre: element.tipo_pago_nombre,
              moneda_nombre: element.moneda_nombre,
              simbolo: element.simbolo,
            })
          }
        } else {
          datos.value.totales.diferiencia += element.monto_real
        }
      })

      console.log(datos.value.totales)
    }
    const GetEmpleado = dato => {
      if (dato != undefined) {
        filtro.value.id_empleado = dato.id
        empleado.value = dato
        consultarDatos()
      } else {
        filtro.value = {
          id_empleado: 0,
          estado_pago: 7,
          fecha: {
            desde: '20200101',
            hasta: '20200101',
          },
        }
        datos.value = { propinas: [] }
        soloVer.value = false
      }
    }
    const selectPropinas = dato => {
      if (dato == -1) {
        datos.value.propinas.forEach(item => (item.checked = true))
      } else if (dato == 0) {
        datos.value.propinas.forEach(item => (item.checked = false))
      } else {
        store.commit('setAlert', {
          message: 'otros',
          type: 'warning',
          
        })
      }
      Totalizar()
    }

    const consultarDatos = () => {
      ComandaService.ReporteComandasPropinas(filtro.value)
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            if (response.data.datos.length > 0) {
              datos.value.propinas = response.data.datos
            } else {
              store.commit('setAlert', {
                message: 'El empleado no tiene propinas pendientes.',
                type: 'warning',

              })
            }
          } else {
            store.commit('setAlert', {
              message: 'ups,' + err,
              type: 'info',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: 'ups,' + err,
            type: 'error',
            
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
              funcion: 'consultarDatos',
          })
        })
        .finally(() => {})
    }

    const validarFiltro = () => {
      let validado = true

      if (!(filtro.value.id_empleado > 0)) {
        validado = false
      }

      return validado
    }
    const Reiniciar = () => {
      soloVer.value = false
      datos.value = {
        propinas: [],
        totales: {
          seleccionados: 0,
          total: 0,
          diferiencia: 0,
          tipos: [],
        },
      }
      filtro.value = {
        id_empleado: 0,
        estado_pago: 7,
        fecha: {
          desde: '20200101',
          hasta: '20200101',
        },
      }
      empleado.value = {}
    }

    return {
      soloVer,
      datos,
      FuncionesGenerales,
      GetEmpleado,
      validarFiltro,
      icons: {
        mdiCart,
        mdiCheckboxMarked,
        mdiCheckboxIntermediate,
        mdiCheckboxMarkedOutline,
        mdiCheckboxBlankOutline,
      },
      consultarDatos,
      selectPropinas,
      Totalizar,
      empleado,
      Reiniciar,
    }
  },
}
</script>