<template>

    <div>
        <v-row>
            <v-col>
                <formulario></formulario>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <lista></lista>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Formulario from './componentes/Formulario.vue'
import Lista from './componentes/Lista.vue'
export default {
    components: { Formulario, Lista },
    setup() {

        return {

        }
    },
}
</script>